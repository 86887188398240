.form-container {
  /* display: flex; */
  background: white;
  padding: 35px 25px;
  width: 100%;
}

.form-item {
  /* display: flex;
  align-items: center; */
  margin-bottom: 20px;
}
.form-item > div {
  flex: 1;
}
.form-item > span {
  color: red;
  font-size: 13px;
}

.answer-row {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

.answer-row > * {
  margin-right: 3%;
}

.isactive-field {
  display: flex;
  align-items: center;
}

.isactive-field .error-message {
  font-size: 17px !important;
  color: red;
  margin-left: 20px;
}

.answers-name-field {
  position: relative;
  min-width: 33.8%;
}

.answers-name-field > span {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: red;
}
